<template>
  <div class="region-indicators-row row">
    <div :class="['row__code row__item', {'row__code-edit': isEdit && !isFilling}]" v-if="isCodeVisible">
      <p v-if="!isEdit || isFilling">{{ data.code }}</p>
      <UiInput placeholder="..." v-else v-model="codeVal" />
    </div>
    <div class="row__title row__item">
      <p v-if="!isEdit || isFilling">{{ data.name }}</p>
      <UiInput placeholder="..." v-else v-model="nameVal" />
    </div>
    <div :class="['row__unit', 'row__item', {'row__unit-view': !isFilling}]">
      <p v-if="!isEdit || isFilling">{{ data.measureName || data.regionParmsMeasure || '-' }}</p>
      <UiInput placeholder="..." v-else v-model="measureVal" />
    </div>
    <div :class="['row__meaning', 'row__item', {'row__meaning-view': isView}]">
      <template v-if="data.dataType !== 'Boolean'">
        <p v-if="!isEdit">{{ getRegionValue }}</p>
        <UiInput placeholder="..." v-else v-model="regionVal" />
      </template>
      <template v-if="data.dataType === 'Boolean'">
        <UiSelect
            :disabled="!isEdit"
            v-model="booleanVal"
            :options="booleanValOptions"
            :placeholder="$t('select-value')"
            grey-arrow
        />
      </template>
    </div>
    <div class="row__source row__item" v-if="isFilling">
      <UiSelect
          :disabled="!isEdit"
          v-model="sourceVal"
          :options="sourceOptions"
          :placeholder="$t('select-source')"
          grey-arrow
      />
    </div>
    <div class="row__points row__item" v-if="isView">
      <p v-if="!isEdit">{{ getPoints }}</p>
      <UiInput placeholder="..." v-else v-model="pointsVal" />
    </div>
    <div class="row__ratio row__item" v-if="isView">
      <p v-if="!isEdit">{{ getEffectiveRatio }}%</p>
      <UiInput placeholder="..." v-else v-model="ratioVal" />
    </div>
    <div class="row__weight-value row__item" v-if="isView">
      <p v-if="!isEdit">{{ getWeightVal }}</p>
      <UiInput placeholder="..." v-else v-model="weightVal" />
    </div>
    <div :class="['row__actions', {'row__actions-view': isView}]" v-if="isFilling">
      <img src="@/assets/svg/admin/edit-default.svg" alt="" @click="isEdit = true" v-if="!isEdit" />
      <img src="@/assets/images/check-active.png" alt="" v-else-if="canSendInfo" @click="sendInfo"/>
      <img src="@/assets/images/check-disable.png" alt="" v-else />
    </div>
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect.vue";
import UiInput from "@/components/ui/UiInput.vue";

export default  {
  name: "RegionIndicatorsRow",
  components: {UiInput, UiSelect},
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isFilling: {
      type: Boolean,
      default: false
    },
    isView: {
      type: Boolean,
      default: false
    },
    isCodeVisible: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isEdit: false,
      sourceVal: {},
      sourceOptions: [
        {name: this.$t('region-data'), value: 'Region'},
        {name: this.$t('public-sources'), value: 'Internet'},
        {name: this.$t('official-statistics'), value: 'Statistics'}
      ],
      codeVal:'',
      nameVal: '',
      measureVal: '',
      regionVal: '',
      pointsVal: '',
      ratioVal: '',
      weightVal: '',
      booleanVal: {},
      booleanValOptions: [
        {name: this.$t('yes'), value: 1},
        {name: this.$t('no'), value: 0}
      ]
    }
  },

  watch: {
    isEdit(val) {
      if(val) {
        this.nameVal = this.data.name
        this.codeVal = this.data.code
        this.measureVal = this.data.measureName
        this.regionVal = this.data.regionValue || this.data.regionValue === 0 ? this.data.regionValue.toString().replace('.', ',') : ''
        setTimeout(() => {
          document.querySelector('.row__title input').focus()
        }, 100)
      }
    }
  },

  computed: {
    getRegionValue () {
      let data = ''
      if(this.isFilling) {
        data = this.data.regionValue || this.data.regionValue === 0 ? this.data.regionValue?.toString().replace('.', ',') : ''
      } else {
        if(this.data.measureName || this.data.regionParmsMeasure) {
          if (this.data.regionParmsValue?.toString().split('.')[1]?.length >= 5) {
            data = this.data.regionParmsValue.toFixed(6).replace('.', ',')
          } else {
            data = this.data.regionParmsValue?.toString().replace('.', ',') || '-'
          }
        } else if (!this.data.measureName && !this.data.regionParmsMeasure) {
          if (this.data.regionParmsValue === 0) {
            data = this.$t('no')
          } else if (this.data.regionParmsValue === 1) {
            data = this.$t('yes')
          }
        }
      }
      return data
    },

    canSendInfo () {
      if(this.data.dataType === 'Boolean') {
        return this.sourceVal?.value && this.nameVal && (this.booleanVal?.value === 1 || this.booleanVal?.value === 0)
      } else {
        return this.nameVal && this.regionVal && this.sourceVal?.value
      }
    },

    getPoints () {
      if (this.data.regionValue?.toString().split('.')[1]?.length > 2) {
        return this.data.regionValue.toFixed(2).replace('.', ',')
      } else {
        return this.data.regionValue?.toString().replace('.', ',') || 0
      }
    },

    getEffectiveRatio () {
      let num =  this.data.effectiveRatio * 100
      if (num?.toString().split('.')[1]?.length >= 5) {
        return num.toFixed(6).replace('.', ',')
      } else {
        return num?.toString().replace('.', ',') || 0
      }
    },

    getWeightVal () {
      let result = this.isView ? this.getPoints?.toString().replace(',', '.') * this.getEffectiveRatio?.toString().replace(',', '.') / 100 : ''
      if (result?.toString().split('.')[1]?.length >= 5) {
        return result.toFixed(6).replace('.', ',')
      } else {
        return result?.toString().replace('.', ',') || 0
      }
    },
  },

  methods: {
    sendInfo () {
      const data = {
        newInfo: {
          name: this.nameVal,
          code: this.codeVal,
          measureName: this.measureVal,
          regionValue: this.data.dataType !== 'Boolean' ? +this.regionVal.toString().replace(',', '.') : this.booleanVal?.value,
          sourceVal: this.sourceVal.value
        },
        itemInfo: this.data
      }

      this.$emit('editItem', data)
      this.isEdit = false;
    }
  },

  created () {
    this.sourceVal = this.sourceOptions.find((option) => {
      return this.data.regionValueSource === option?.value
    });

    this.booleanVal = this.booleanValOptions.find((option) => {
      return this.data.regionValue === option?.value
    });
  }
}
</script>

<style lang="scss" scoped>
.row {
  display: flex;

  &__item {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    min-height: 40px;
    width: 100%;
    font-size: 14px;
    line-height: 120.2%;
    color: #1B1A1F;
    border: 1px solid #f5f5f5;

    p {
      font-family: "Gotham Pro Regular";
    }

    ::v-deep .ui-select {
      z-index: auto;

      &__inner {
        border: none;
        padding: 0;

        &-text {
          font-family: "Gotham Pro Regular";
        }
      }

      &__label {
        display: none;
      }
    }

    ::v-deep .ui-select__options {
      top: 40px;
      left: -30px;
      text-align: left;
      width: 160px;
    }

    ::v-deep .ui-input {
      input {
        border: none;
        padding: 5px 30px 5px 3px;
        height: auto;
      }
    }
  }

  &__title {
    max-width: 400px;
  }

  &__code {
    max-width: 80px;
    display: flex;
    justify-content: center;
    padding: 0;

    &-edit {
      justify-content: flex-start;
    }
  }

  &__unit {
    max-width: 160px;
    justify-content: center;

    &-view {
      max-width: 131px !important;
    }
  }

  &__meaning {
    justify-content: center;
    max-width: 160px;

    &-view {
      max-width: 110px !important;
    }
  }

  &__source {
    justify-content: center;
    max-width: 200px;
  }

  &__points {
    justify-content: center;
    max-width: 90px;
  }

  &__ratio {
    justify-content: center;
    max-width: 130px;
  }

  &__weight-value {
    justify-content: center;
    max-width: 130px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 1px solid #f5f5f5;
    max-width: 70px;
    width: 100%;

    &-view {
      max-width: 50px !important;
    }

    img {
      width: 17px;
      height: 17px;
      cursor: pointer;
    }
  }
}
</style>
