<template>
  <div class="region-indicators-table table">
    <div class="table__body">
      <div :class="['table__body-header', {'table__body-header-view': isView}]">
        <div
            v-for="(item, key) in descriptionList"
            :key="key"
            class="table__body-header-item"
            :style="{maxWidth: item.width + 'px', paddingLeft: item.paddingLeft + 'px', textAlign: item.textAlign}"
        >{{ item.title }}</div>
      </div>
      <div class="table__body-main" v-if="data.length">
        <RegionIndicatorsRow
            v-for="(item, key) in data"
            :key="key"
            :data="item"
            :isEdit="isEdit"
            :isFilling="isFilling"
            :isView="isView"
            :is-code-visible="isCodeVisible"
            @editItem="$emit('editItem', $event)"
        />
      </div>
      <div v-else class="empty">
        {{ $t('empty') }}
      </div>
    </div>
  </div>
</template>

<script>
import RegionIndicatorsRow from "@/components/template/moderatorRegion/regionIndicators/RegionIndicatorsRow.vue";
export default {
  name: "RegionIndicatorsTable",
  components: {RegionIndicatorsRow},
  props: {
    descriptionList: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    isFilling: {
      type: Boolean,
      default: false
    },
    isView: {
      type: Boolean,
      default: false
    },
    isCodeVisible: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isEdit: false
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 1000px;
  width: 100%;
  animation: fade-out 1s ease-out;
  transition: 0.3s;

  @keyframes fade-out {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px 0 rgba(153, 153, 153, 0.20);

    &-header {
      display: flex;
      align-items: center;
      background: #E21F1F;
      padding: 10px 50px 10px 20px;
      border-radius: 20px 20px 0 0;

      &-view {
        padding: 10px 0 10px 20px !important;
      }

      &-item {
        width: 100%;
        color: #FFFFFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }

    &-main {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
